// Function to clean a URL by removing the protocol (http/https) and www prefix
export const cleanUrl = (url: string): string => {
  const cleanedUrl = url.toLowerCase().replace(/^(https?:\/\/)?(www\.)?/, ""); // Convert URL to lowercase and then clean it
  return cleanedUrl;
};

// Function to get the image URL for a company, falling back to a default image if necessary
export const getImageUrl = (company: any, token: string, noCompanyLogo: string): string => {
  // Return default logo if company or token is missing
  if (!company || !token) {
    return noCompanyLogo;
  }

  // Return the company's logo URL if it exists
  if (company?.logo?.url) {
    return company.logo.url;
  }

  // Return default logo if no URL is available
  return noCompanyLogo;
};
